.no-results-card {

    ion-icon {
        font-size: 6rem;
        margin: 3rem 0;
    }

    ion-text {

        * {
            margin-bottom: 1rem;
        }

        h2 {
            font-size: 1.75rem;
        }

        p {
            font-size: 1.25rem;
        }
    }
}