.color-item-has-actions {
    
    ion-label {
        margin-left: 0px;
        margin-right: 0px;
        width: 136px;
        max-width: 136px;

        @media screen and (min-width: 576px) {
            margin-left: 12px;
            margin-right: 12px;
            width: 250px;
            max-width: 250px;
        }

        @media screen and (min-width: 768px) {
            width: 400px;
            max-width: 400px;
        }

        @media screen and (min-width: 992px) {
            width: 600px;
            max-width: 600px;
        }

        @media screen and (min-width: 1200px) {
            width: 800px;
            max-width: 800px;
        }
    }

    ion-button {
        margin-left: 8px;
    }

    ion-input {
        margin: 0px 8px;
        width: 60px;
        max-width: 60px;
        text-align: center;
    }
}